var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("setup-table", {
        attrs: {
          title: _vm.showHidden ? "Hidden Vehicle" : "Vehicle",
          items: _vm.items,
          headers: _vm.headers,
          loading: _vm.loading,
          clearItem: !_vm.showForm || !_vm.editedItem.id,
        },
        on: {
          createItem: function ($event) {
            return _vm.createItem()
          },
          editItem: _vm.editItem,
          changeStatus: _vm.changeStatus,
          deleteItems: _vm.deleteItems,
        },
        scopedSlots: _vm._u(
          [
            _vm.showForm
              ? {
                  key: "itemForm",
                  fn: function () {
                    return [
                      _c("vehicle-form", {
                        ref: "vehicleForm",
                        attrs: {
                          value: _vm.editedItem,
                          vehicleTypes: _vm.filterVehicleTypes,
                          vehicleLocations: _vm.vehicleLocations,
                          drivers: _vm.drivers,
                        },
                        on: { submit: _vm.saveItem, closeForm: _vm.closeForm },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: `item.synced`,
              fn: function ({ item }) {
                return [
                  item.synced
                    ? _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-sync"),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.type`,
              fn: function ({ item }) {
                return [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.vehicleTypesById[item.type]
                          ? _vm.vehicleTypesById[item.type].name
                          : ""
                      )
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.location`,
              fn: function ({ item }) {
                return [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(_vm.getVehicleLocation(item))),
                  ]),
                ]
              },
            },
            {
              key: `item.lift`,
              fn: function ({ item }) {
                return [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(
                      _vm._s(item.wheelchairLift ? "mdi-check" : "mdi-close")
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.status`,
              fn: function ({ item }) {
                return [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(item.active ? "Active" : "Inactive")),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }